.footer {
    position: relative;
    display:block;
    height: 500px;
    margin-top: -100px;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='671px' height='294px' viewBox='0 0 671 294' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 60.1 (88133) - https://sketch.com --%3E%3Ctitle%3EFOOTER%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Version-4' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Blue-footer' transform='translate(0.000000, -7.000000)'%3E%3Cg id='FOOTER' transform='translate(-1.000000, 7.000000)'%3E%3Cpath d='M0,110.066406 C302.335937,134.352865 526.335937,97.6640625 672,0 L671.796875,293.945312 L0,293.945312 L0,110.066406 Z' id='Path-2' fill='%23009FB9'%3E%3C/path%3E%3Cpath d='M0,131.926615 C302.335937,153.325809 526.335937,120.998708 672,34.9453125 L671.796875,293.945312 L0,293.945312 L0,131.926615 Z' id='Path-2' fill='%2300B5CF' opacity='0.648228237'%3E%3C/path%3E%3Cpath d='M0,110.066406 C302.335937,134.352865 526.335937,97.6640625 672,0 L671.796875,293.945312 L0,293.945312 L0,110.066406 Z' id='Path-2' fill='%23009FB9' opacity='0.655575707' transform='translate(336.000000, 146.972656) scale(-1, 1) translate(-336.000000, -146.972656) '%3E%3C/path%3E%3Cpath d='M0,136.305499 C302.335937,157.126336 526.335937,125.67294 672,41.9453125 L671.796875,293.945312 L0,293.945312 L0,136.305499 Z' id='Path-2' fill='%23009FB9' opacity='0.655575707' transform='translate(336.000000, 167.945312) scale(-1, 1) translate(-336.000000, -167.945312) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

    .copy {
        font-size: 13px;
        position: absolute;
        bottom: 30px;
        left: 30px;
        color: rgba(255,255,255,.75);
    }
}